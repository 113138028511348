import React, { lazy } from 'react'
import './App.css'
import './style/custom.css'
import { Routes, Route, Navigate } from 'react-router-dom'
import BlockRoutes from './UTILS/lib/PrivateRoutes/BlockRoutes'
import LoginRoutes from './UTILS/lib/PrivateRoutes/LoginRoutes'
import DojangSelectRoutes from './UTILS/lib/PrivateRoutes/DojangSelectRoutes'
import ContainerOnlyTopbar from './UI/LAYOUT/CONTAINER/ContainerOnlyTopbar'
import ContainerWithoutSidebar from './UI/LAYOUT/CONTAINER/ContainerWithoutSidebar'
import ContainerOnlyTopbarWithLogout from './UI/LAYOUT/CONTAINER/ContainerOnlyTopbarWithLogout'
import LayoutDojang from './UI/LAYOUT/LAYOUT_ROUTES/LayoutDojang'
import LayoutAssociation from './UI/LAYOUT/LAYOUT_ROUTES/LayoutAssociation'
import LayoutDojangManagement from './UI/LAYOUT/LAYOUT_ROUTES/LayoutDojangManagement'
import LayoutDojangManagementTrial from './UI/LAYOUT/LAYOUT_ROUTES/LayoutDojangManagementTrial'
import LayoutGuide from './UI/LAYOUT/LAYOUT_ROUTES/LayoutGuide'
import Temporary from './PAGES/COMMON/Service/Temporary'
import BillStatusPage from './PAGES/USERDOJANG/BILL/CHECK/BillStatusPage'
import BillResultComponent from './PAGES/USERDOJANG/BILL/RESULT/BillResultComponent'
import ServicePgSign from './PAGES/USERDOJANG/SERVICE/PG/ServicePgSign'
import CheckJoinedPG from './UTILS/lib/PrivateRoutes/CheckJoinedPG'
import PgSignComponent from './PAGES/USERDOJANG/SERVICE/PGForm/PgSignComponent'
import CheckJoinedHealthcare from './UTILS/lib/PrivateRoutes/CheckJoinedHealthcare'
import ManageStudentBulkEdit from './PAGES/USERDOJANG/MANAGE/STUDENT/Bulk/ManageStudentBulkEdit'
import CheckIsLogin from './UTILS/lib/PrivateRoutes/CheckIsLogin'

// COMMON
const Home = lazy(() => import('./PAGES/HOME/Home'))
const Login = lazy(() => import('./PAGES/COMMON/ACCOUNT/LOGIN/AccountLogin'))
const AccountLoginRedirect = lazy(() => import('./PAGES/COMMON/ACCOUNT/LOGIN/AccountLoginRedirect'))
const Logout = lazy(() => import('./PAGES/COMMON/ACCOUNT/LOGIN/AccountLogout'))
const AccountSign = lazy(() => import('./PAGES/COMMON/ACCOUNT/SIGN/AccountSign'))
const FindUserName = lazy(() => import('./PAGES/COMMON/ACCOUNT/FIND/FindUserName'))
const FindUserPW = lazy(() => import('./PAGES/COMMON/ACCOUNT/FIND/FindUserPW'))
const CantAccessPage = lazy(() => import('./PAGES/COMMON/CantAccessPage'))
const Privacy = lazy(() => import('./PAGES/COMMON/Policy/Privacy/Privacy'))
const SportsentNotice = lazy(() => import('./PAGES/COMMON/SPORTSENT/NOTICE/SportsentNotice'))

// ACCOUNT - SIGN
const AccountSignKwanjang = lazy(() => import('./PAGES/COMMON/ACCOUNT/SIGN/KWANJANG/AccountSignKwanjang'))
const AccountSignSabum = lazy(() => import('./PAGES/COMMON/ACCOUNT/SIGN/SABUM/AccountSignSabum'))
const AccountSignStudent = lazy(() => import('./PAGES/COMMON/ACCOUNT/SIGN/STUDENT/AccountSignStudent'))
const AccountSignParent = lazy(() => import('./PAGES/COMMON/ACCOUNT/SIGN/PARENT/AccountSignParent'))

// DOJANG HOME && DASHBOARD
const DojangHome = lazy(() => import('./PAGES/USERDOJANG/HOME/DojangHome'))
const DashBoard = lazy(() => import('./PAGES/USERDOJANG/DASHBOARD/DashBoard'))

// UTILS
const DojangRegist = lazy(() => import('./PAGES/USERDOJANG/DOJANG/REGIST/DojangRegist'))
const DojangList = lazy(() => import('./PAGES/USERDOJANG/DOJANG/LIST/DojangList'))
const DojangSelect = lazy(() => import('./PAGES/USERDOJANG/DOJANG/SELECT/DojangSelect'))

// INVITATION
const InvitationSend = lazy(() => import('./PAGES/USERDOJANG/INVITATION/SEND/InvitationSend'))
const InvitaionSendStudent = lazy(() => import('./PAGES/USERDOJANG/INVITATION/SEND/STUDENT/InvitaionSendStudent'))
const InvitationSendTeacher = lazy(() => import('./PAGES/USERDOJANG/INVITATION/SEND/TEACHER/InvitationSendTeacher'))
const InvitaionSendStudentTransmission = lazy(() =>
  import('./PAGES/USERDOJANG/INVITATION/SEND/STUDENT/TRANSMISSION/InvitaionSendStudentTransmission'),
)
const InviteInquiry = lazy(() => import('./PAGES/USERDOJANG/INVITATION/INQUIRY/InvitationInquiry'))

// STUDENT
const ManageStudentList = lazy(() => import('./PAGES/USERDOJANG/MANAGE/STUDENT/LIST/ManageStudentList'))
const ManageStudentRestList = lazy(() => import('./PAGES/USERDOJANG/MANAGE/STUDENT/REST/LIST/ManageStudentRestList'))
const ManageStudentDetail = lazy(() => import('./PAGES/USERDOJANG/MANAGE/STUDENT/DETAIL/ManageStudentDetail'))
const ManageStudentRegist = lazy(() => import('./PAGES/USERDOJANG/MANAGE/STUDENT/REGIST/ManageStudentRegist'))
const StudentRegistLump = lazy(() => import('./PAGES/USERDOJANG/MANAGE/STUDENT/backup/StudentRegistLump'))
// const StudentRegistLump = lazy(() => import('./PAGES/USERDOJANG/MANAGE/STUDENT/REGIST/LUMP/StudentRegistLump'))
const StudentRegistExist = lazy(() => import('./PAGES/USERDOJANG/MANAGE/STUDENT/REGIST/EXIST/StudentRegistExist'))
const StudentRegistNew = lazy(() => import('./PAGES/USERDOJANG/MANAGE/STUDENT/REGIST/NEW/StudentRegistNew'))
const StudentRegistOthers = lazy(() => import('./PAGES/USERDOJANG/MANAGE/STUDENT/REGIST/OTHERS/StudentRegistOthers'))

// MANAGE - DOJANG
const ManageDojang = lazy(() => import('./PAGES/USERDOJANG/MANAGE/DOJANG/ManageDojang'))

// MANAGE - SABUM
const ManageSabumList = lazy(() => import('./PAGES/USERDOJANG/MANAGE/SABUM/LIST/ManageSabumList'))
const ManageSabumDetail = lazy(() => import('./PAGES/USERDOJANG/MANAGE/SABUM/DETAIL/ManageSabumDetail'))

// MANAGE - CLASS && BUS && LEVELSYSTEM
const ManageClassList = lazy(() => import('./PAGES/USERDOJANG/MANAGE/CLASS/LIST/ManageClassList'))
const ManageBusList = lazy(() => import('./PAGES/USERDOJANG/MANAGE/BUS/LIST/ManageBusList'))
const ManageLevelSystem = lazy(() => import('./PAGES/USERDOJANG/MANAGE/LEVELSYSTEM/ManageLevelSystem'))
const ManageLevelSystemRegist = lazy(() =>
  import('./PAGES/USERDOJANG/MANAGE/LEVELSYSTEM/REGIST/ManageLevelSystemRegist'),
)

// ATTENDANCE
const AttendanceRegist = lazy(() => import('./PAGES/USERDOJANG/ATTENDANCE/REGIST/AttendanceRegist'))
const AttendanceStatus = lazy(() => import('./PAGES/USERDOJANG/ATTENDANCE/STATUS/AttendanceStatus'))

// BILL
const BillManagement = lazy(() => import('./PAGES/USERDOJANG/BILL/MANAGEMENT/BillManagement'))
const BillRead = lazy(() => import('./PAGES/USERDOJANG/BILL/READ/BillRead'))
const BillSend = lazy(() => import('./PAGES/USERDOJANG/BILL/SEND/BillSend'))
const BillSettings = lazy(() => import('./PAGES/USERDOJANG/BILL/SETTINGS/BillSettings'))

// LEVELUP
const LevelupRegistSelect = lazy(() => import('./PAGES/USERDOJANG/LEVELUP/REGIST/SELECT/LevelupRegistSelect'))
const LevelupRegist = lazy(() => import('./PAGES/USERDOJANG/LEVELUP/REGIST/LevelupRegist'))
const LevelupHistory = lazy(() => import('./PAGES/USERDOJANG/LEVELUP/HISTORY/LevelupHistory'))

// MYPAGE
const MypageAccount = lazy(() => import('./PAGES/USERDOJANG/MYPAGE/ACCOUNT/MypageAccount'))
const MypageResume = lazy(() => import('./PAGES/USERDOJANG/MYPAGE/RESUME/MypageResume'))
const MypageSelectInvitation = lazy(() => import('./PAGES/USERDOJANG/MYPAGE/SELECTINVITATION/MypageSelectInvitation'))

// SERVICE - PAYMENT
const ServicePayment = lazy(() => import('./PAGES/USERDOJANG/SERVICE/PAYMENT/ServicePayment'))
const ServicePaymentRequest = lazy(() => import('./PAGES/USERDOJANG/SERVICE/PAYMENT/REQUEST/ServicePaymentRequest'))
const ServicePaymentRequestSuccess = lazy(() =>
  import('./PAGES/USERDOJANG/SERVICE/PAYMENT/REQUEST/SUCCESS/ServicePaymentRequestSuccess'),
)

// SERVICE - PAYSSAM
const ServicePayssam = lazy(() => import('./PAGES/USERDOJANG/SERVICE/PAYSSAM/ServicePayssam'))
const ServicePayssamRequest = lazy(() => import('./PAGES/USERDOJANG/SERVICE/PAYSSAM/REQUEST/ServicePayssamRequest'))

// SERVICE - INQUIRY
const ServiceInquiry = lazy(() => import('./PAGES/USERDOJANG/SERVICE/INQUIRY/ServiceInquiry'))

// APP
const AppNoticeList = lazy(() => import('./PAGES/USERDOJANG/APP/NOTICE/LIST/AppNoticeList'))
const AppNoteList = lazy(() => import('./PAGES/USERDOJANG/APP/NOTE/LIST/AppNoteList'))
const AppScheduleList = lazy(() => import('./PAGES/USERDOJANG/APP/SCHEDULE/LIST/AppScheduleList'))

// INVITION
const Invitation = lazy(() => import('./PAGES/COMMON/INVITATION/Invitation'))
const AcceptInvitation = lazy(() => import('./PAGES/COMMON/INVITATION/Components/AcceptInvitation'))

// ASSOCIATION
const AssociationHome = lazy(() => import('./PAGES/USERASSOCIATION/ASSOCIATION/HOME/AssociationHome'))
const AssociationLevelUp = lazy(() => import('./PAGES/USERASSOCIATION/ASSOCIATION/LEVELUP/AssociationLevelup'))
const AssociationLogin = lazy(() => import('./PAGES/COMMON/AssociationLogin'))
const AssociationLoginAfter = lazy(() => import('./PAGES/COMMON/AssociationLoginAfter'))
const AssociationSign = lazy(() => import('./PAGES/COMMON/AssociationSign'))
const AssociationDojangList = lazy(() => import('./PAGES/USERASSOCIATION/Dojang/List/DojangList'))
// const AssociationHome = lazy(() => import('./PAGES/USERASSOCIATION/AssociationHome/AssociationHome'))
// const AssociationLevelUpList = lazy(() => import('./PAGES/USERASSOCIATION/Levelup/List/LevelupList'))

// HEALTH CARE - HOME
const HealthCareHome = lazy(() => import('./PAGES/HOME/HEALTHCARE/HealthCareHome'))
const ApplicationSection = lazy(() => import('./PAGES/HOME/HEALTHCARE/ApplicationSection'))

// HEALTH CARE - ADMIN
const AdminHealthCareFormList = lazy(() => import('./PAGES/HOME/HEALTHCARE/ADMIN/LIST/AdminHealthCareFormList'))

// PAYMENT
const PaymentHome = lazy(() => import('./PAGES/HOME/PAYMENT/PaymentHome'))

// // BODIX
// const BodixHome = lazy(() => import('./PAGES/HOME/BODIX/BodixHome'))
// const ApplicationSection = lazy(() => import('./PAGES/HOME/BODIX/ApplicationSection'))

// // BODIX - ADMIN
// const AdminBodixFormList = lazy(() => import('./PAGES/HOME/BODIX/ADMIN/LIST/AdminBodixFormList'))

// GUIDE
const GuideHome = lazy(() => import('./PAGES/GUIDE/GuideHome'))
const GuideDojangHome = lazy(() => import('./PAGES/GUIDE/COMMON/DOJANGHOME/GuideDojangHome'))
const GuideSignWithLogin = lazy(() => import('./PAGES/GUIDE/KWANJANG/START/Components/GuideSignWithLogin'))
const GuideDojangRegist = lazy(() => import('./PAGES/GUIDE/KWANJANG/START/Components/GuideDojangRegist'))
const GuideServicePayment = lazy(() => import('./PAGES/GUIDE/KWANJANG/START/Components/GuideServicePayment'))
const GuideServiceInquiry = lazy(() => import('./PAGES/GUIDE/KWANJANG/START/Components/GuideServiceInquiry'))
const GuideIDPWFind = lazy(() => import('./PAGES/GUIDE/KWANJANG/START/Components/GuideIDPWFind'))
const GuideLogout = lazy(() => import('./PAGES/GUIDE/KWANJANG/START/Components/GuideLogout'))
const GuideMembershipWithdrawal = lazy(() =>
  import('./PAGES/GUIDE/KWANJANG/START/Components/GuideMembershipWithdrawal'),
)
const GuideInvite = lazy(() => import('./PAGES/GUIDE/KWANJANG/FUNCTION/Components/GuideInvite'))
const GuideStudent = lazy(() => import('./PAGES/GUIDE/KWANJANG/FUNCTION/Components/GuideStudent'))
const GuideClassRegist = lazy(() => import('./PAGES/GUIDE/KWANJANG/FUNCTION/Components/GuideClassRegist'))
const GuideClassAdd = lazy(() => import('./PAGES/GUIDE/KWANJANG/FUNCTION/Components/GuideClassAdd'))
const GuideBus = lazy(() => import('./PAGES/GUIDE/KWANJANG/FUNCTION/Components/GuideBus'))
const GuideGeub = lazy(() => import('./PAGES/GUIDE/KWANJANG/FUNCTION/Components/GuideGeub'))
const GuideAttendance = lazy(() => import('./PAGES/GUIDE/KWANJANG/FUNCTION/Components/GuideAttendance'))
const GuideBill = lazy(() => import('./PAGES/GUIDE/KWANJANG/FUNCTION/Components/GuideBill'))
const GuideLevelupRegist = lazy(() => import('./PAGES/GUIDE/KWANJANG/FUNCTION/Components/GuideLevelupRegist'))
const GuideCertificate = lazy(() => import('./PAGES/GUIDE/KWANJANG/FUNCTION/Components/GuideCertificate'))
const GuideDojangInquiry = lazy(() => import('./PAGES/GUIDE/KWANJANG/FUNCTION/Components/GuideDojangInquiry'))
const GuideDojangChange = lazy(() => import('./PAGES/GUIDE/KWANJANG/FUNCTION/Components/GuideDojangChange'))
const GuideKwanjangInfo = lazy(() => import('./PAGES/GUIDE/KWANJANG/FUNCTION/Components/GuideKwanjangInfo'))

// FITNESS
const FitnessList = lazy(() => import('./PAGES/USERDOJANG/FITNESS/LIST/FitnessList'))
const FitnessRegist = lazy(() => import('./PAGES/USERDOJANG/FITNESS/REGIST/FitnessRegist'))
const FitnessManagement = lazy(() => import('./PAGES/USERDOJANG/FITNESS/MANAGEMENT/FitnessManagement'))
const FitnessResult = lazy(() => import('./PAGES/USERDOJANG/FITNESS/RESULT/FitnessResult'))

// HEALTH CARE - DOJANG
const HealthCareList = lazy(() => import('./PAGES/USERDOJANG/HEALTHCARE/LIST/HealthcareList'))
// const HealthCareDetail = lazy(() => import('./PAGES/USERDOJANG/HEALTHCARE/DETAIL/HealthCareDetail'))

// ADMIN - LOGIN
const AdminLogin = lazy(() => import('./PAGES/ADMIN/LOGIN/AdminLogin'))

// QR - ATTENDANCE
const QrAttendanceLogin = lazy(() => import('./PAGES/QR/ATTENDANCE/LOGIN/QrAttendanceLogin'))
const QrAttendanceRegist = lazy(() => import('./PAGES/QR/ATTENDANCE/REGIST/QrAttendanceRegist'))

// HOMEPAGE - DOJANG
const DojangHomepage = lazy(() => import('./PAGES/USERDOJANG/HOMEPAGE/DojangHomepage'))

const Router = () => {
  return (
    <Routes>
      <Route
        path='/service/temporary'
        element={<Temporary />}
      />
      <Route
        path='/policy/privacy'
        element={<Privacy />}
      />

      <Route element={<BlockRoutes />}>
        {/* MasterTeacherRoutes */}
        <Route element={<LoginRoutes userRole='KWANJANG-SABUM' />}>
          <Route element={<DojangSelectRoutes />}>
            <Route element={<LayoutDojangManagement />}>
              <Route
                path='/home'
                element={<DojangHome />}
              />
              <Route
                path='/dashboard'
                element={<DashBoard />}
              />

              {/* MANAGE - INVITE */}
              <Route
                path='/invitation/send'
                element={<InvitationSend />}
              />
              <Route
                path='/invitation/send/student'
                element={<InvitaionSendStudent />}
              />
              <Route
                path='/invitation/send/student/transmission'
                element={<InvitaionSendStudentTransmission />}
              />
              <Route
                path='/invitation/send/teacher'
                element={<InvitationSendTeacher />}
              />
              <Route
                path='/invitation/inquiry'
                element={<InviteInquiry />}
              />

              {/* MANAGE - CLASS */}
              <Route
                path='/manage/class/list'
                element={<ManageClassList />}
              />

              {/* MANAGE - BUS */}
              <Route
                path='/manage/bus'
                element={<ManageBusList />}
              />

              {/* MANAGE - STUDENT */}
              <Route
                path='/manage/student/list'
                element={<ManageStudentList />}
              />
              <Route
                path='/manage/student/rest/list'
                element={<ManageStudentRestList />}
              />
              <Route
                path='/manage/student/detail'
                element={<ManageStudentDetail />}
              />
              <Route
                path='/manage/student/regist'
                element={<ManageStudentRegist />}
              />
              <Route
                path='/manage/student/regist/new'
                element={<StudentRegistNew />}
              />
              <Route
                path='/manage/student/regist/exist'
                element={<StudentRegistExist />}
              />
              <Route
                path='/manage/student/regist/lump'
                element={<StudentRegistLump />}
              />
              <Route
                path='manage/student/regist/bulk'
                element={<ManageStudentBulkEdit />}
              />
              <Route
                path='/manage/student/regist/others'
                element={<StudentRegistOthers />}
              />

              {/* ATTENDANCE */}
              <Route
                path='/attendance/regist'
                element={<AttendanceRegist />}
              />
              <Route
                path='/attendance/status'
                element={<AttendanceStatus />}
              />

              {/* LEVEL UP */}
              <Route
                path='/levelup/history'
                element={<LevelupHistory />}
              />

              {/* HEALTH CARE */}
              <Route element={<CheckJoinedHealthcare />}>
                <Route
                  path='/healthcare/list'
                  element={<HealthCareList />}
                />
                {/* 
              <Route
                path='/healthcare/detail'
                element={<HealthCareDetail />}
              /> */}
              </Route>

              {/* FITNESS */}
              <Route
                path='fitness/list'
                element={<FitnessList />}
              />
              <Route
                path='fitness/regist'
                element={<FitnessRegist />}
              />
              <Route
                path='fitness/management'
                element={<FitnessManagement />}
              />

              <Route
                path='/mypage/account'
                element={<MypageAccount />}
              />
              <Route
                path='/mypage/resume'
                element={<MypageResume />}
              />
              <Route
                path='/service/inquiry'
                element={<ServiceInquiry />}
              />
            </Route>
          </Route>
          <Route
            path='/mypage/selectinvitation'
            element={<MypageSelectInvitation />}
          />
          <Route
            path='/dojang/select'
            element={<DojangSelect />}
          />
          <Route
            path='/dojang/list'
            element={<DojangList />}
          />
        </Route>

        {/* MasterRoutes */}
        <Route element={<LoginRoutes userRole='KWANJANG' />}>
          <Route element={<DojangSelectRoutes />}>
            <Route element={<LayoutDojangManagement />}>
              {/* MANAGE - STUDENT */}

              {/* MANAGE - INVITE */}

              {/* MANAGE - SABUM */}
              <Route
                path='/manage/sabum/list'
                element={<ManageSabumList />}
              />
              <Route
                path='/manage/sabum/detail'
                element={<ManageSabumDetail />}
              />

              {/* MANAGE - DOJANGINFO */}
              <Route
                path='/manage/dojang'
                element={<ManageDojang />}
              />

              {/* MANAGE - CLASS */}

              {/* MANAGE - BUS */}

              {/* MANAGE - LEVEL */}
              <Route
                path='/manage/levelsystem'
                element={<ManageLevelSystem />}
              />
              <Route
                path='/manage/levelsystem/regist'
                element={<ManageLevelSystemRegist />}
              />

              {/* ATTENDANCE */}

              {/* BILL */}
              <Route
                path='/bill/management'
                element={<BillManagement />}
              />
              <Route
                path='/bill/settings'
                element={<BillSettings />}
              />
              <Route element={<CheckJoinedPG />}>
                <Route
                  path='/bill/read'
                  element={<BillRead />}
                />
                <Route
                  path='/bill/send'
                  element={<BillSend />}
                />
              </Route>

              {/* LEVELUP */}
              <Route
                path='/levelup/regist'
                element={<LevelupRegist />}
              />
              <Route
                path='/levelup/regist/select'
                element={<LevelupRegistSelect />}
              />

              {/* SERVICE - PAYMENT */}
              <Route
                path='/service/payment'
                element={<ServicePayment />}
              />
              <Route
                path='/service/payment/success'
                element={<ServicePaymentRequestSuccess />}
              />

              <Route
                path='/service/pg/intro'
                element={<ServicePgSign />}
              />

              <Route
                path='/service/pg/signForm'
                element={<PgSignComponent />}
              />

              {/* SERVICE - PAYSSAM */}
              <Route
                path='/service/payssam'
                element={<ServicePayssam />}
              />

              {/* APP - NOTICE */}
              <Route
                path='/app/notice/list'
                element={<AppNoticeList />}
              />

              {/* APP - DIARY */}
              <Route
                path='/app/note/list'
                element={<AppNoteList />}
              />

              {/* APP - SCHEDULE */}
              <Route
                path='/app/schedule/list'
                element={<AppScheduleList />}
              />

              {/* HOMEPAGE */}
              <Route
                path='/hp'
                element={<DojangHomepage />}
              />
            </Route>

            <Route element={<LayoutDojang />}></Route>
          </Route>
          <Route element={<ContainerWithoutSidebar />}>
            <Route
              path='/dojang/regist'
              element={<DojangRegist />}
            />
            <Route
              path='/service/payment/request'
              element={<ServicePaymentRequest />}
            />
            <Route
              path='/service/payment/request/success'
              element={<ServicePaymentRequestSuccess />}
            />
            <Route
              path='/service/payssam/request'
              element={<ServicePayssamRequest />}
            />
          </Route>
        </Route>

        {/* TeacherRoutes */}
        <Route element={<LoginRoutes userRole='SABUM' />}>
          <Route element={<DojangSelectRoutes />}>
            <Route element={<LayoutDojangManagement />}></Route>
          </Route>
        </Route>

        {/* AssociationRoutes */}
        <Route element={<LoginRoutes userRole='ASSOCIATION' />}>
          <Route element={<LayoutAssociation />}>
            <Route
              path='/association/home'
              element={<AssociationHome />}
            />
            <Route
              path='/association/levelup'
              // path='/association/levelup/list'
              element={<AssociationLevelUp />}
            />
            <Route
              path='/association/dojang/list'
              element={<AssociationDojangList />}
            />
          </Route>
        </Route>

        <Route element={<ContainerWithoutSidebar />}>
          <Route
            path='/account/login'
            element={<Login />}
          />
          <Route
            path='/account/login/redirect'
            element={<AccountLoginRedirect />}
          />
        </Route>
      </Route>

      {/* QR - ATTENDANCE */}
      <Route element={<ContainerWithoutSidebar />}>
        {/* qr -------------------------------------------- */}
        <Route
          path='/qr/login'
          element={<QrAttendanceLogin />} // login
        />

        <Route
          element={<CheckIsLogin />} // block, children
        >
          <Route
            path='/qr/reg'
            element={<QrAttendanceRegist />}
          />
        </Route>

        {/* fitness(paps) */}
        <Route
          path='/fitness/result'
          element={<FitnessResult />}
        />
      </Route>

      {/* CommonRoutes */}
      <Route element={<ContainerWithoutSidebar />}>
        {/* ADMIN */}
        <Route
          path='/adminlogin/A2k!jvI2'
          element={<AdminLogin />}
        />

        <Route
          path='/'
          element={<Home />}
        />
        <Route
          path='/account/login/association'
          element={<AssociationLogin />}
        />
        <Route
          path='/account/login/association/loading'
          element={<AssociationLoginAfter />}
        />

        <Route
          path='/account/login/association/sign'
          element={<AssociationSign />}
        />

        {/* HEALTH CARE */}
        <Route
          path='/healthcare'
          element={<HealthCareHome />}
        />
        <Route
          path='/healthcare/application'
          element={<ApplicationSection />}
        />

        {/* HEALTH CARE - ADMIN */}
        <Route
          path='/healthcare/application/list'
          element={<AdminHealthCareFormList />}
        />

        {/* Payment */}
        <Route
          path='/payment'
          element={<PaymentHome />}
        />

        {/* bill */}
        <Route
          path='/bill/:bill_id'
          element={<BillStatusPage />}
        />
        <Route
          path='/bill/result'
          element={<BillResultComponent />}
        />

        {/* BODIX */}
        {/* <Route
          path='/bodix'
          element={<BodixHome />}
        />
        <Route
          path='/bodix/application'
          element={<ApplicationSection />}
        /> */}

        {/* BODIX - ADMIN */}
        {/* <Route
          path='/bodix/application/list'
          element={<AdminBodixFormList />}
        /> */}

        <Route
          path='/account/error/access'
          element={<CantAccessPage />}
        />

        <Route element={<ContainerOnlyTopbar />}>
          <Route
            path='/account/sign'
            element={<AccountSign />}
          />
          <Route
            path='/account/sign/kwanjang'
            element={<AccountSignKwanjang />}
          />
          <Route
            path='/account/sign/sabum'
            element={<AccountSignSabum />}
          />
          <Route
            path='/account/sign/student'
            element={<AccountSignStudent />}
          />
          <Route
            path='/account/sign/parent'
            element={<AccountSignParent />}
          />

          <Route
            path='/account/find/username'
            element={<FindUserName />}
          />
          <Route
            path='/account/find/password'
            element={<FindUserPW />}
          />

          {/* SportsENT Notice */}
          <Route
            path='/sportsent/notice'
            element={<SportsentNotice />}
          />
        </Route>

        {/* Invitation */}
        <Route
          path='/in'
          element={<Invitation />}
        />
        {/* Invitation success */}
        <Route
          path='/in/success'
          element={<AcceptInvitation />}
        />

        <Route element={<ContainerOnlyTopbarWithLogout />}>
          <Route
            path='/account/myinfo'
            element={<MypageAccount />}
          />
          <Route
            path='/account/resume'
            element={<MypageResume />}
          />
        </Route>
      </Route>

      <Route
        path='/logout'
        element={<Logout />}
      />
      {/* <Route path="/service/intro" element={<ServiceIntro />} /> */}

      {/* TRIAL */}
      <Route element={<LayoutDojangManagementTrial />}></Route>

      {/* GUIDE */}
      <Route element={<LayoutGuide />}>
        <Route
          path='/guide'
          element={<GuideHome />}
        />
        <Route
          path='/guide/home'
          element={<GuideDojangHome />}
        />
        {/* COMMON */}

        {/* KWANJANG */}
        <Route
          path='/guide/kwanjang/sign'
          element={<GuideSignWithLogin />}
        />
        <Route
          path='/guide/kwanjang/dojang/regist'
          element={<GuideDojangRegist />}
        />
        <Route
          path='/guide/kwanjang/service/payment'
          element={<GuideServicePayment />}
        />
        <Route
          path='/guide/kwanjang/service/inquiry'
          element={<GuideServiceInquiry />}
        />
        <Route
          path='/guide/kwanjang/find'
          element={<GuideIDPWFind />}
        />
        <Route
          path='/guide/kwanjang/logout'
          element={<GuideLogout />}
        />
        <Route
          path='/guide/kwanjang/withdrawal'
          element={<GuideMembershipWithdrawal />}
        />

        <Route
          path='/guide/kwanjang/invite'
          element={<GuideInvite />}
        />
        <Route
          path='/guide/kwanjang/student'
          element={<GuideStudent />}
        />
        <Route
          path='/guide/kwanjang/class/regist'
          element={<GuideClassRegist />}
        />
        <Route
          path='/guide/kwanjang/class/add'
          element={<GuideClassAdd />}
        />
        <Route
          path='/guide/kwanjang/bus'
          element={<GuideBus />}
        />
        <Route
          path='/guide/kwanjang/geub'
          element={<GuideGeub />}
        />
        <Route
          path='/guide/kwanjang/attendance'
          element={<GuideAttendance />}
        />
        <Route
          path='/guide/kwanjang/bill'
          element={<GuideBill />}
        />
        <Route
          path='/guide/kwanjang/levelup/regist'
          element={<GuideLevelupRegist />}
        />
        <Route
          path='/guide/kwanjang/levelup/certificate'
          element={<GuideCertificate />}
        />
        <Route
          path='/guide/kwanjang/dojang/inquiry'
          element={<GuideDojangInquiry />}
        />
        <Route
          path='/guide/kwanjang/dojang/change'
          element={<GuideDojangChange />}
        />
        <Route
          path='/guide/kwanjang/myinfo'
          element={<GuideKwanjangInfo />}
        />
        {/* TEACHER */}
      </Route>

      <Route
        path='*'
        element={<Navigate to='/' />}
      />
    </Routes>
  )
}

export default Router
