import React from 'react'
import { FormattedMessage } from 'react-intl'
import { Link } from '@mui/material'
import Typography from '../../../../../UI/ATOM/TEXT/HOME/Typography'
import RequestPgButton from './RequestPgButton'
import OpenInNewRoundedIcon from '@mui/icons-material/OpenInNewRounded'

const s3URL = process.env.REACT_APP_AWS_S3_FILE_URL

const PGInfo = () => {
  return (
    <div className='px-[50px] py-[35px] bg-white text-[#000000]'>
      <Typography className='text-[20px] pb-[10px]'>
        <FormattedMessage id='[PG가입 안내]' />
      </Typography>

      <div className='flex flex-col text-[18px] pb-[20px]'>
        <Typography>
          <FormattedMessage id='태권월드 결제서비스를 이용하기 위해서, PG가입이 필요합니다.' />
        </Typography>
        <Typography>
          <FormattedMessage id='결제금액은 태권월드를 거치지않고 PG사(결제대행사)로부터 바로 정산됩니다.' />
        </Typography>
      </div>

      <div className='flex flex-col text-[18px] pb-[20px]'>
        <Typography>
          <FormattedMessage id='"PG 가입요청"으로 가입요청을 보내면, 검토후 전자계약서 링크를 보내드립니다.' />
        </Typography>
        <Typography>
          <FormattedMessage id='전자계약서 서명 및 승인 후 PG가입이 완료됩니다. (영업일 기준 3~5일 내외)' />
        </Typography>
      </div>

      <div className='block text-[18px] pb-[20px]'>
        <Typography>
          <FormattedMessage id='※ 더 자세한 내용을 확인하시려면, 아래 내용을 클릭하여 주세요.' />
        </Typography>
        <div className='block'>
          <Link
            className='cursor-pointer pt-[8px] pl-2 inline-block'
            href={`${s3URL}/document/intro_tkw_payment_1.pdf`}
            target='_blank'
          >
            <span className='flex justify-center items-center text-[16px]'>
              PG가입절차 및 결제방식 안내 <OpenInNewRoundedIcon className='ml-[10px]' />
            </span>
          </Link>
        </div>
        <div className='block'>
          <Link
            className='cursor-pointer pt-[8px] pl-2 inline-block'
            href={`${s3URL}/document/intro_tkw_payment_2.pdf`}
            target='_blank'
          >
            <span className='flex justify-center items-center text-[16px]'>
              납부관리 연동 및 청구서발행 안내 <OpenInNewRoundedIcon className='ml-[10px]' />
            </span>
          </Link>
        </div>
        {/* <div className='block'>
          <Link
            className='cursor-pointer pt-[8px] pl-2 inline-block'
            href={`${s3URL}/document/intro_tkw_payment_3.pdf`}
            target='_blank'
          >
            <span className='flex justify-center items-center text-[16px]'>
              결제방법 안내 (학부모 소개용) <OpenInNewRoundedIcon className='ml-[10px]' />
            </span>
          </Link>
        </div> */}
      </div>

      <div className='flex flex-col text-[18px] pb-[20px]'>
        <Typography>
          <FormattedMessage id='- 결제서비스 및 PG가입문의' />
        </Typography>
        <Typography className='font-bold'>
          <FormattedMessage id='태권월드 CS팀 010-7615-6646' />
        </Typography>
      </div>

      <div className='pt-[20px] pb-[40px] border-x-0 border-t-0 border-b border-solid border-[#C6C6C6]'>
        <RequestPgButton />
      </div>
    </div>
  )
}

export default PGInfo
